<template>
    <div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Status</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <v-select :searchable="true" :options="optionStatus" :clearable="true" v-model="selectedStatus">
                </v-select>
            </div>
        </div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                <vs-th sort-key="">Src. Territory</vs-th>
                <vs-th sort-key="">Dest. Territory</vs-th>
                <vs-th sort-key="">Posting Date</vs-th>
                <vs-th sort-key="">Notes</vs-th>
                <vs-th sort-key="">File</vs-th>
                <vs-th sort-key="">Status</vs-th>
                <vs-th sort-key="">Remark</vs-th>
                <vs-th sort-key="">Created By</vs-th>
                <vs-th sort-key="">Created On</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button @click.stop="handleView(data[indextr].id)" size="small" color="warning"
                                icon-pack="feather" icon="icon-eye" title="View" style="margin-right: 5px" />
                        </div>
                    </vs-td>
                    <vs-td :data="data[indextr].supplier_code">
                        {{ data[indextr].supplier_code + " " + data[indextr].supplier_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].src_territory_code">
                        {{ data[indextr].src_territory_code + " " + data[indextr].src_territory_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].dest_territory_code">
                        {{ data[indextr].dest_territory_code + " " + data[indextr].dest_territory_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].posting_date">
                        {{ formatDate(data[indextr].posting_date) }}
                    </vs-td>
                    <vs-td :data="data[indextr].notes">
                        {{ data[indextr].notes }}
                    </vs-td>
                    <vs-td :data="data[indextr].file_url">
                        <a :href="data[indextr].file_url" target="_blank">File</a>
                    </vs-td>
                    <vs-td :data="data[indextr].status">
                        {{ data[indextr].status }}
                    </vs-td>
                    <vs-td :data="data[indextr].remark">
                        {{ data[indextr].remark }}
                    </vs-td>
                    <vs-td :data="data[indextr].created_at">
                        {{ data[indextr].created_by }}
                    </vs-td>
                    <vs-td :data="data[indextr].created_at">
                        {{ utcToLocal(data[indextr].created_at) }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        draw: {
            type: Number,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            optionStatus: ["Imported", "Failed"],
            selectedStatus: "",
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleView(id) {
            this.$emit("logImportLine", id);
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/simple-sto-stock-out/import/get-data-tabel", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        status: this.selectedStatus,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    watch: {
        draw() {
            this.getData();
        },
        selectedStatus() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
};
</script>
