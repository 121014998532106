<template>
    <div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Status</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <v-select :searchable="true" :options="optionStatus" :clearable="true" v-model="selectedStatus">
                </v-select>
            </div>
        </div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th sort-key="">Sku Code External</vs-th>
                <vs-th sort-key="">Sku Code Internal</vs-th>
                <vs-th sort-key="">Sku Name</vs-th>
                <vs-th sort-key="">Doc. Stock Out</vs-th>
                <vs-th sort-key="">Src. Territory</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key="">Src. Warehouse</vs-th>
                <vs-th sort-key="">Item Status</vs-th>
                <vs-th sort-key="">Qty</vs-th>
                <vs-th sort-key="">Qty UOM</vs-th>
                <vs-th sort-key="">HU</vs-th>
                <vs-th sort-key="">Total Price</vs-th>
                <vs-th sort-key="">Qty Reason</vs-th>
                <vs-th sort-key="">ID Reason</vs-th>
                <vs-th sort-key="">Item Description</vs-th>
                <vs-th sort-key="">Doc. Stock In</vs-th>
                <vs-th sort-key="">Dest. Territory</vs-th>
                <vs-th sort-key="">Time Arrived</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Item Status In</vs-th>
                <vs-th sort-key="">Qty In</vs-th>
                <vs-th sort-key="">Qty Uom In</vs-th>
                <vs-th sort-key="">HU In</vs-th>
                <vs-th sort-key="">Total Price In</vs-th>
                <vs-th sort-key="">Status</vs-th>
                <vs-th sort-key="">Reason</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].sku_code_external">
                        {{ data[indextr].sku_code_external }}
                    </vs-td>
                    <vs-td :data="data[indextr].sku_code_internal">
                        {{ data[indextr].sku_code_internal }}
                    </vs-td>
                    <vs-td :data="data[indextr].sku_name">
                        {{ data[indextr].sku_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].doc_sto_out">
                        {{ data[indextr].doc_sto_out }}
                    </vs-td>
                    <vs-td :data="data[indextr].src_territory">
                        {{ data[indextr].src_territory }}
                    </vs-td>
                    <vs-td :data="data[indextr].delivery_date">
                        {{ data[indextr].delivery_date }}
                    </vs-td>
                    <vs-td :data="data[indextr].src_warehouse">
                        {{ data[indextr].src_warehouse }}
                    </vs-td>
                    <vs-td :data="data[indextr].item_status_out">
                        {{ data[indextr].item_status_out }}
                    </vs-td>
                    <vs-td :data="data[indextr].qty">
                        {{ data[indextr].qty }}
                    </vs-td>
                    <vs-td :data="data[indextr].qty_uom">
                        {{ data[indextr].qty_uom }}
                    </vs-td>
                    <vs-td :data="data[indextr].hu">
                        {{ data[indextr].hu }}
                    </vs-td>
                    <vs-td :data="data[indextr].total_price">
                        {{ data[indextr].total_price }}
                    </vs-td>
                    <vs-td :data="data[indextr].qty_reason">
                        {{ data[indextr].qty_reason }}
                    </vs-td>
                    <vs-td :data="data[indextr].id_reason">
                        {{ data[indextr].id_reason }}
                    </vs-td>
                    <vs-td :data="data[indextr].item_desc">
                        {{ data[indextr].item_desc }}
                    </vs-td>
                    <vs-td :data="data[indextr].doc_sto_in">
                        {{ data[indextr].doc_sto_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].dest_territory">
                        {{ data[indextr].dest_territory }}
                    </vs-td>
                    <vs-td :data="data[indextr].time_arrived">
                        {{ data[indextr].time_arrived }}
                    </vs-td>
                    <vs-td :data="data[indextr].dest_warehouse">
                        {{ data[indextr].dest_warehouse }}
                    </vs-td>
                    <vs-td :data="data[indextr].item_status_in">
                        {{ data[indextr].item_status_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].qty_in">
                        {{ data[indextr].qty_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].qty_uom_in">
                        {{ data[indextr].qty_uom_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].hu_in">
                        {{ data[indextr].hu_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].total_price_in">
                        {{ data[indextr].total_price_in }}
                    </vs-td>
                    <vs-td :data="data[indextr].status">
                        {{ data[indextr].status }}
                    </vs-td>
                    <vs-td :data="data[indextr].remark">
                        {{ data[indextr].remark }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        logImportID: {
            type: Number,
        },
    },
    data() {
        return {
            table: this.tableDefaultState(),
            optionStatus: ["Imported", "Failed"],
            selectedStatus: "",
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/simple-sto-stock-out/import/get-data-line", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        log_import_id: this.logImportID,
                        status: this.selectedStatus,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
    },
    watch: {
        logImportID() {
            this.getData();
        },
        selectedStatus() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
