<template>
    <vx-card title="Simple Stock Transfer Order Import">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <vs-button class="mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                    @click="handleBack()">Back</vs-button>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="mt-2" color="primary" type="border" icon-pack="feather" icon="icon-download"
                    @click="handleDownloadTemplate()">Template Download</vs-button>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly && id">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>External Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="externalCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Supplier 
                    :value="this.selectedSupplier" 
                    @selected="(val) => { this.selectedSupplier = val }"
                ></Supplier>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory 
                    :value="this.selectedSrcTerritory" 
                    @selected="(val) => { this.selectedSrcTerritory = val }"
                ></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory 
                    :value="this.selectedDestTerritory" 
                    @selected="(val) => { this.selectedDestTerritory = val }"
                ></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date 
                    :value="this.postingDate" 
                    @selected="(val) => { this.postingDate = val }"
                ></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>File (xls, xlsx)</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="fileInput" accept=".xlsx, .xls" @change="handleChangeFile" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="mt-2" color="primary" type="border" icon-pack="feather" icon="icon-upload"
                    @click="handleImport()">Import</vs-button>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <vs-tabs v-model="activeTab">
                    <vs-tab label="Import">
                        <table-log-import 
                            :draw="draw"
                            @logImportLine="handleLogImportLine"
                        ></table-log-import>
                    </vs-tab>
                    <vs-tab label="Import Line">
                        <table-log-import-line
                            :logImportID="this.logImportID"
                        ></table-log-import-line>
                    </vs-tab>
                </vs-tabs>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Territory from "./component/Territory.vue";
import Supplier from "./component/Supplier.vue";
import Date from "./component/Date.vue";
import vSelect from "vue-select";
import TableLogImport from "./component/TableLogImport.vue";
import TableLogImportLine from "./component/TableLogImportLine.vue";

export default {
    components: {
        Territory,
        Supplier,
        Date,
        vSelect,
        TableLogImport,
        TableLogImportLine
    },
    data() {
        return {
            id: null,
            activeTab: 0,
            logImportID: 0,
            readonly: true,
            code: null,
            externalCode: null,
            selectedSupplier: null,
            selectedSrcTerritory: null,
            selectedDestTerritory: null,
            postingDate: null,
            notes: null,
            month: null,
            file: null,
            draw: 0,
        }
    },
    mounted() {
        // var now = moment()
        // now.add(1, 'days')
    },
    methods: {
        handleLogImportLine(id) {
            this.activeTab = 1;
            this.logImportID = id;
        },
        handleDownloadTemplate() {
            this.$vs.loading();
            var fileTitle = "SIMPLE_STO_TEMPLATE";
            this.$http
                .get("/api/wms/v1/outbound/simple-sto-stock-out/import/template", {
                    params: {
                        month: this.month ? moment(this.month).format("YYYY-MM-DD") : null,
                    },
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/octet-stream",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "error") {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        var fileURL = window.URL.createObjectURL(new Blob([resp]));
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;

                        fileLink.setAttribute(
                            "download",
                            fileTitle.toUpperCase() + ".xlsx"
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }
                });
        },
        setDefault() {
            this.id = null
            this.selectedSupplier = null
            this.selectedSrcTerritory = null
            this.selectedDestTerritory = null
            this.postingDate = null
            this.notes = null
            this.file = null
        },
        handleBack() {
            this.$router.push({
                name: "outbound.simple-sto-stock-out",
            });
        },
        handleImport() {
            if (!this.selectedSupplier) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select supplier",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedSrcTerritory) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select source territory",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDestTerritory) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select destination territory",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.file) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "File is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("supplier_id", this.selectedSupplier.id);
            formData.append("src_territory_id", this.selectedSrcTerritory.id);
            formData.append("dest_territory_id", this.selectedDestTerritory.id);
            formData.append("posting_date", this.postingDate ? moment(this.postingDate).format("YYYY-MM-DD") : null);
            formData.append("notes", this.notes);
            formData.append("file", this.file);

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to import this excel?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/outbound/simple-sto-stock-out/import/create", formData)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
        
                                this.$vs.loading.close();
                                this.draw++;
                                this.setDefault();
                                location.reload();
                            } else {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
        handleImportTest() {
            this.$http
                .post("/api/wms/v1/outbound/simple-sto-stock-out/testing-data")
                .then((resp) => {
                    if (resp.code == 200) {
                        location.reload();
                    }
                })
        },
    },
}
</script>